import React, { Component } from "react";
import "./index.css";

class NotYetImplemented extends Component {
  render() {
    return (
      <div>
        <img id="logo" src="./assets/head4che_white.svg"></img>
        <h1>
          Cette page est en construction. Revenez bientôt pour découvrir les
          avancements du projet !
        </h1>
      </div>
    );
  }
}

export default NotYetImplemented;
