import React from 'react';
import ReactDOM from 'react-dom/client';
import NotYetImplemented from './NotYetImplemented/NotYetImplemented';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotYetImplemented />
  </React.StrictMode>
);
